<template>
  <Button label="Atras"
          icon="pi pi-arrow-left"
          class="mr-2 p-button-danger"
          @click="$router.go(-1)"
  />
  <Button label="Nuevo"
          icon="pi pi-plus"
          class="mr-2"
          @click="openNew"
  />

  <Dialog v-model:visible="display" :style="{width: '450px'}" header="Nuevo socio" :modal="true" class="p-fluid">
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name"
                 v-model.trim="Socio.nombre"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field">
      <label for="nit">Nit</label>
      <InputText id="nit"
                 v-model.trim="Socio.nit"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="openNew"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveModal"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import { ref } from 'vue'
import useSocios from '../../../hooks/useSocios'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'SociosHeader',
  setup () {
    const display = ref(false)
    const { saveSocio } = useSocios()
    const Socio = ref({
      nombre: '',
      nit: ''
    })
    const toast = useToast()

    const openNew = () => {
      display.value = !display.value
      Socio.value.nombre = ''
      Socio.value.nit = ''
    }

    const saveModal = async () => {
      display.value = !display.value
      await saveSocio({ ...Socio.value })
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Socio creado correctamente', life: 3000 })
    }

    return {
      openNew,
      display,
      Socio,
      saveModal
    }
  }
}
</script>

<style scoped>

</style>
