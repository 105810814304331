<template>
  <DataTable :value="getStoreSocios.rows"
             dataKey="id"
             responsiveLayout="scroll"
             class="p-datatable-sm mt-2"
  >
    <template #header>
      <div class="flex justify-between">
        <h5 class="mb-2 md:m-0 p-as-md-center">
          Administrar Socios
        </h5>
        <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="search" placeholder="Buscar..." @keyup="searchSocios"/>
            </span>
      </div>
    </template>
    <Column field="nombre"
            header="Nombre"
            style="padding-left: 2.5rem;"
    >
      <template #body="{data}">
            <span>
              {{ data.nombre }}
            </span>
      </template>
    </Column>
    <Column header="Acciones"
            style="display: flex; justify-content: end; padding-right: 2.5rem;"
            bodyStyle="display: flex; justify-content: end; padding-right: 2.5rem;">
      <template #body="{data}">
        <Button icon="pi pi-pencil"
                class="p-button-rounded mr-2"
                v-tooltip.top="'Editar'"
                @click="edit(data)"
        />
        <Button icon="pi pi-trash"
                class="p-button-rounded p-button-danger mr-2"
                v-tooltip.top="'Eliminar'"
                @click="remove(data.id)"
        />
        <Button icon="pi pi-book"
                class="p-button-rounded p-button-help"
                v-tooltip.top="'Estados Financieros'"
                @click="$router.push({name: 'pharmasan.oportunidades.licitaciones.herramientas.socios.estados-financieros', params: {id: data.id}})"
        />
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.first" :rows="10" :totalRecords="getStoreSocios.count" @page="onPage($event)"></Paginator>

  <Dialog v-model:visible="display" :style="{width: '450px'}" header="Nuevo socio" :modal="true" class="p-fluid">
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name"
                 v-model.trim="Socio.nombre"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field">
      <label for="nit">Nit</label>
      <InputText id="nit"
                 v-model.trim="Socio.nit"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="display = !display"
      />
      <Button
        label="Actualizar"
        icon="pi pi-check"
        class=""
        @click="updateModal"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import useSocios from '../../../hooks/useSocios'
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'

export default {
  name: 'Table',
  setup () {
    // constants
    const toast = useToast()
    const store = useStore()
    const { getSocios, updateSocio, removeSocio } = useSocios()
    const getStoreSocios = computed(() => store.getters['storeLicitaciones/getStoreSocios'])
    const paginator = ref({
      rows: 10,
      first: 0
    })
    const search = ref('')
    const Socio = ref({
      nombre: '',
      nit: '',
      id: null
    })
    const display = ref(false)

    // methods
    onMounted(async () => {
      await getSocios({
        offset: paginator.value.first,
        limit: paginator.value.rows
      })
    })

    const searchSocios = async (event) => {
      await getSocios({
        offset: paginator.value.first,
        limit: paginator.value.rows,
        filters: {
          nombre: search.value
        }
      })
    }

    const edit = (data) => {
      Socio.value = {
        nombre: data.nombre,
        nit: data.nit,
        id: data.id
      }
      display.value = true
    }

    const updateModal = async () => {
      await updateSocio(Socio.value.id, {
        nombre: Socio.value.nombre,
        nit: Socio.value.nit
      })
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Socio actualizado correctamente', life: 3000 })
      display.value = false
    }

    const remove = (id) => {
      Swal.fire({
        title: 'Advertencia',
        text: '¿Seguro/a desea eliminar el socio? No podrá deshacer esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar!',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          await removeSocio(id)
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Socio eliminado correctamente', life: 3000 })
        }
      })
    }

    return {
      getStoreSocios,
      search,
      searchSocios,
      paginator,
      getSocios,
      Socio,
      display,
      edit,
      updateModal,
      remove
    }
  }
}
</script>

<style scoped>

</style>
