<template>
  <div class="box mb-4 p-2 mt-2">
    <Header/>
    <Table/>
  </div>
</template>

<script>
import Header from './components/Header'
import Table from './components/Table'

export default {
  name: 'HomeSocios',
  components: {
    Header,
    Table
  }
}
</script>

<style scoped>

</style>
